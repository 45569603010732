@use "../variables.scss" as *;

.project-display-container {
  position: relative;
  height: fit-content;
  width: 320px;
  display: flex;
  flex-direction: column;
  padding: 15px;

  // glass effect
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba($main-color, 0.1);
  background-color: rgba($sub-color1, 0.2);
  backdrop-filter: blur(5px);
  border: 1px solid rgba($sub-color1, 0.3);

  .separator {
    width: 50px;
    border-bottom: 2px $sub-color2 solid;
    margin-top: 10px;
    margin-bottom: 30px;

    &.tiny {
      width: 30px;
      margin-bottom: 10px;
      margin-top: 5px;
    }
  }

  .project-links {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .link-container {
      margin-bottom: 8px;
    }
  }

  .project-description {
    text-align: justify;
  }

  .project-tags {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    column-gap: 25px;
    margin-top: 20px;
    text-transform: capitalize;

    span {
      color: $accented-color;
    }
  }
}

@media (min-width: $sm-width) {
  .project-display-container {
    padding: 20px;
    width: 500px;
  }
}

@media (min-width: $xl-width) {
  .project-display-container {
    padding: 30px;
    width: 650px;
  }
}

@media (min-width: $xxl-width) {
  .project-display-container {
    width: 800px;
  }
}
