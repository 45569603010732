@use "./variables.scss" as *;

.fullView {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  color: $main-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // background
  background: $background-color;
  background-size: 200% 100%;
  animation: argon-gradient 20s linear infinite;

  @keyframes argon-gradient {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }

  &.dive-in {
    opacity: 0;
    animation: dive-in-anime 1s ease-in-out forwards;
    pointer-events: none;

    @keyframes dive-in-anime {
      0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }

      100% {
        transform: translate(-50%, -50%) scale(2);
        opacity: 0;
      }
    }
  }

  &.hidden {
    display: none;
  }

  button {
    margin-top: 50px;
  }

  .content-container {
    position: relative;
    padding: 8px;
    padding-bottom: 12px;
    margin-top: 115px;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // glass effect
    border-radius: 16px;
    border: 1px solid rgba($sub-color1, 0.3);
    background-color: rgba($sub-color1, 0.2);
    box-shadow: 0 4px 30px rgba($main-color, 0.1);
    backdrop-filter: blur(5px);

    .mainMessage {
      text-align: center;
    }

    .underline {
      width: 0;
      height: 2px;
      background-color: $sub-color2;
      transition: width 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    .langBtn {
      position: absolute;
      bottom: 0;
      right: 7px;
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
      padding: 15px;
      margin-left: auto;

      &:hover,
      &.active {
        .underline {
          width: 100%;
        }
      }
    }

    .fv-lang-dropdown-container {
      display: none;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;
      position: absolute;
      bottom: -60px;
      right: 0;
      z-index: 10;

      // glass effect
      border-radius: 16px;
      border: 1px solid rgba($sub-color1, 0.3);
      box-shadow: 0 4px 30px rgba($main-color, 0.1);
      background-color: rgba($sub-color1, 0.2);
      backdrop-filter: blur(5px);

      &.hide-anime {
        display: flex;
        animation: slide-out 0.5s;

        @keyframes slide-out {
          0% {
            opacity: 1;
            transform: translateX(0);
          }

          100% {
            opacity: 0;
            transform: translateX(100%);
          }
        }
      }

      &.display {
        display: flex;
        animation: slide-in 0.5s forwards;

        @keyframes slide-in {
          from {
            opacity: 0;
            transform: translateX(100%);
          }

          to {
            opacity: 1;
            transform: translateX(0);
          }
        }
      }

      .lang-dropdown-item {
        cursor: pointer;
        padding: 15px;
        width: 20px;
        border-top: none;
        display: flex;
        justify-content: center;
        align-items: center;

        .lang-content-container {
          display: flex;
          flex-direction: column;
        }

        &:hover {
          .lang-content-container .underline {
            width: 100%;
          }
        }
      }
    }

    ul {
      width: 350px;
      margin: 0;
      list-style-type: none;
      padding-left: 0;

      li {
        position: relative;
        padding-left: 25px;
        margin-bottom: 6px;

        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          left: 0;
          border-bottom: 2px solid $sub-color2;
          width: 15px;
          margin-top: 13px;
        }
      }
    }
  }
}

.content-to-reveil {
  display: none;

  &.is-shown {
    display: block;
  }
}

// width media queries
@media (min-width: $sm-width) {
  .fullView {
    .content-container {
      padding: 20px;

      ul {
        width: 480px;
      }
    }
  }
}

@media (min-width: $lg-width) {
  .fullView {
    .content-container {
      padding: 30px;
    }
  }
}

// height media queries
@media (min-height: 750px) {
  .fullView {
    .content-container {
      margin-top: 160px;
    }
  }
}

@media (min-height: 850px) {
  .fullView {
    .content-container {
      margin-top: 200px;
    }
  }
}

@media (min-height: 950px) {
  .fullView {
    .content-container {
      margin-top: 250px;
    }
  }
}

@media (min-height: 1080px) {
  .fullView {
    .content-container {
      margin-top: 320px;
    }
  }
}
