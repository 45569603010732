@use "./variables.scss" as *;

.header-container {
  position: sticky;
  top: 8px;
  z-index: 10;
  margin-bottom: 50px;

  .underline {
    width: 0;
    height: 2px;
    background-color: $sub-color2;
    transition: width 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}

.navContainer {
  width: 100%;
  display: flex;
  justify-content: center;

  // glass effect
  border-radius: 16px;
  border: 1px solid rgba($sub-color1, 0.3);
  background-color: rgba($sub-color1, 0.2);
  box-shadow: 0 4px 30px rgba($main-color, 0.1);
  backdrop-filter: blur(5px);

  .nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }

  .navItem {
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    padding: 15px;

    &.langBtn {
      margin-left: auto;
    }

    &:hover,
    &.active {
      .underline {
        width: 100%;
      }
    }
  }
}

.lang-dropdown-container {
  display: none;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  top: 58px;
  right: 0;
  z-index: 10;

  // glass effect
  border-radius: 16px;
  border: 1px solid rgba($sub-color1, 0.3);
  box-shadow: 0 4px 30px rgba($main-color, 0.1);
  background-color: rgba($sub-color1, 0.2);
  backdrop-filter: blur(5px);

  &.hide-anime {
    display: flex;
    animation: slide-out 0.5s;

    @keyframes slide-out {
      0% {
        opacity: 1;
        transform: translateX(0);
      }

      100% {
        opacity: 0;
        transform: translateX(100%);
      }
    }
  }

  &.display {
    display: flex;
    animation: slide-in 0.5s forwards;

    @keyframes slide-in {
      from {
        opacity: 0;
        transform: translateX(100%);
      }

      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  .lang-dropdown-item {
    cursor: pointer;
    padding: 15px;
    width: 20px;
    border-top: none;
    display: flex;
    justify-content: center;
    align-items: center;

    .content-container {
      display: flex;
      flex-direction: column;
    }

    &:hover {
      .content-container .underline {
        width: 100%;
      }
    }
  }
}

@media (min-width: $sm-width) {
  .nav .navItem {
    &.first,
    &.langBtn {
      margin-left: auto;
    }
  }
}
