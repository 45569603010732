@use "./variables.scss" as *;

@font-face {
  font-family: RubikRegular;
  src:
    local("RubikRegular"),
    url("../fonts/Rubik/Rubik-Regular.ttf") format("truetype");
}

@font-face {
  font-family: RubikBold;
  src:
    local("RubikBold"),
    url("../fonts/Rubik/Rubik-Bold.ttf") format("truetype");
}

@font-face {
  font-family: RubikItalic;
  src:
    local("RubikItalic"),
    url("../fonts/Rubik/Rubik-Italic.ttf") format("truetype");
}

@font-face {
  font-family: RubikBoldItalic;
  src:
    local("RubikBoldItalic"),
    url("../fonts/Rubik/Rubik-BoldItalic.ttf") format("truetype");
}

body,
span {
  margin: 0;
  font-family: RubikRegular, sans-serif;
  font-size: $font-size-regular;
  color: $font-color;
  overflow-x: hidden;
}

body {
  // background
  background: $background-color;
  background-size: 200% 100%;
  animation: moving-gradient 20s linear infinite;

  @keyframes moving-gradient {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }
}

html {
  background-color: $fallback-bg-color;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}

strong {
  font-weight: 400;
  color: $accented-color;
}

// better scrollbar
::-webkit-scrollbar {
  width: 12px;
  display: none;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border: 3px solid transparent;
  border-radius: 10px;
}
