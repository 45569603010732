$fallback-bg-color: #e96eb5;
$background-color: linear-gradient(to right, #5a7fef, #b873d8, #e96eb5, #fe7890, #fd8f73);
$main-color: #1a1a1a;
$sub-color1: #fff;
$sub-color2: #4af626;
$font-color: #1a1a1a;
$accented-color: #98e888;
$font-regular: "RubikRegular";
$font-bold: "RubikBold";
$font-italic: "RubikItalic";
$font-bold-italic: "RubikBoldItalic";
$font-size-small: 13px;
$font-size-regular: 16px;
$font-size-big: 22px;
$sm-width: 640px;
$md-width: 768px;
$lg-width: 1024px;
$xl-width: 1280px;
$xxl-width: 1536px;
