@use "../variables.scss" as *;

.link-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.defaultLink {
  border-color: $main-color;
  font-family: $font-regular;
  font-size: $font-size-regular;
  color: $main-color;

  &.uppercase {
    text-transform: uppercase;
  }

  &.lowercase {
    text-transform: lowercase;
  }

  &.capitalize {
    text-transform: capitalize;
  }

  &.negative {
    color: $sub-color1;
  }

  &.highlighted {
    color: $accented-color;
  }

  &:hover {
    color: $sub-color2;

    &.negative {
      color: $main-color;
    }
  }
}
