@use "./variables.scss" as *;

.loader-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  .loader-text {
    margin-top: 20px;
    font-size: $font-size-small;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: $sub-color2;
      cursor: default;
    }
  }
}
