@use "./variables.scss" as *;

.home-container {
  display: flex;
  flex-direction: column;

  .info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .self-pic {
    margin-top: 30px;

    .image {
      object-fit: scale-down;
      object-position: top;
    }
  }
}

@media (min-width: $md-width) {
  .home-container {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    column-gap: 30px;

    .self-pic {
      margin-top: 0;
      height: calc(100vh - 102.5px - 101px - 10px);
    }
  }
}
