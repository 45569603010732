@use "variables.scss" as *;

.layoutContainer {
  margin-left: 10px;
  margin-right: 10px;

  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;

    &.full {
      min-height: calc(100vh - 102.5px - 103px);
      margin-bottom: 0;
    }

    &.left {
      align-items: flex-start;
    }
  }
}

@media (min-width: $sm-width) {
  .layoutContainer {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (min-width: $md-width) {
  .layoutContainer {
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media (min-width: $lg-width) {
  .layoutContainer {
    margin-left: 50px;
    margin-right: 50px;
  }
}

@media (min-width: $xl-width) {
  .layoutContainer {
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media (min-width: $xxl-width) {
  .layoutContainer {
    margin-left: 150px;
    margin-right: 150px;
  }
}
